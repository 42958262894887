<template>
  <div id="merchantOrderList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>商户订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              clearable
              placeholder="请选择"
              @change="
                getMerchantList();
                filtrate.merchantId = '';
                getList(1);
              "
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li v-if="filtrate.parkingLotId">
            <span class="label">商户:</span>
            <el-select
              clearable
              v-model="filtrate.merchantId"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.userId"
                :label="item.shopName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">订单类型:</span>
            <el-select
              v-model="filtrate.recordType"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_recordType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="endTimeChange"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addVehicle"
              type="primary"
              @click="exportExcel"
              v-if="buttonList.includes('stopOrderList-export')"
              >导出报表
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="merchantName" label="商户名称" align="center">
        </el-table-column>
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="amountChange" label="金额" align="center">
        </el-table-column>
        <el-table-column prop="recordType" label="订单类型" align="center">
        </el-table-column>
        <el-table-column label="关联订单号" align="center">
          <template #default="scope">
            <span class="orderId" @click="goToPage(scope.row.orderId)">{{
              scope.row.orderId
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="recordTime" label="时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <exportDialog ref="exportDialog" />
  </div>
</template>
<script>
export default {
  components: {
    exportDialog: () => import("./exportDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        parkingLotId: "",
        merchantId: "",
        startTime: "",
        endTime: "",
        recordType: "优惠券扣款",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
      merchantList: [],
    };
  },
  created() {
    this.getList();
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    endTimeChange(value) {
      Object.assign(this.filtrate, {
        endTime: this.$moment(this.$moment(value).format("YYYY-MM-DD 23:59:59"))
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
      this.getList(1);
    },
    goToPage(orderId) {
      this.$router.push({
        name: "stopOrderList",
        params: {
          keyword: orderId,
        },
      });
    },
    exportExcel() {
      this.$refs.exportDialog.form = {
        time: [
          this.filtrate.startTime ? this.filtrate.startTime : "",
          this.filtrate.endTime ? this.filtrate.endTime : "",
        ],
        parkingLotId: this.filtrate.parkingLotId,
        recordType: this.filtrate.recordType,
      };
      this.$refs.exportDialog.handleShow();
    },
    reset() {
      this.filtrate = {
        keyword: "",
        parkingLotId: "",
        merchantId: "",
        startTime: "",
        endTime: "",
        recordType: "优惠券扣款",
      };
      this.getList(1);
    },
    async getMerchantList() {
      try {
        let res = await this.$http.post("/parking/shop/list/all", {
          parkingLotId: this.filtrate.parkingLotId,
        });
        if (res.code === 0) {
          this.merchantList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/order/merchant/list", {
          ...this.filtrate,
          pageNum: page,
          pageSize: this.table.pageSize,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.recordTime = this.$moment(item.recordTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            switch (item.refundStatus) {
              case "申请退款中":
                item.refundStatusText = "等待审批";
                break;
              case "已同意":
                item.refundStatusText = "已同意";
                break;
              case "已拒绝":
                item.refundStatusText = "已拒绝(重新申请)";
                break;
              default:
                item.refundStatusText = "申请退款";
            }
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#merchantOrderList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .exportBtn {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .orderId {
    cursor: pointer;
  }
}
</style>
